<template>
  <div class="header__wrapper">
    <div class="header__block">
      <div class="left-menu">
        <div>
          <router-link :to="{'name':'usa-port'}">USA Port</router-link>
        </div>
        <div class="hamburger-menu mobile-only" v-if="type === 'mobile'">
          <input id="menu__toggle" type="checkbox" />
          <label class="menu__btn" for="menu__toggle">
            <span></span>
          </label>
          <ul class="menu__box">
            <li><router-link :to="{'name':'calculator'}">Calculator</router-link></li>
            <li><router-link :to="{'name':'car-fax'}">Car Fax</router-link></li>
          </ul>
        </div>
      </div>
      <div>
        <img style="height: 70px" :src="require('../assets/logo.png')" alt="">
      </div>
      <div class="right-menu">
        <div>
          <router-link :to="{'name':'calculator'}">Calculator</router-link>
        </div>
        <div>
          <router-link :to="{'name':'car-fax'}">Car Fax</router-link>
        </div>
        <div class="logout" v-if=" isAuth" @click="logout">
          <span class="d-flex">
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 22C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V3C4 2.73478 4.10536 2.48043 4.29289 2.29289C4.48043 2.10536 4.73478 2 5 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V6H18V4H6V20H18V18H20V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5ZM18 16V13H11V11H18V8L23 12L18 16Z" fill="#DB2D35"/>
            </svg>
          </span>
          <span>Wychodzić</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from "@/eventBus";
export default {
  name: "header-index",
  data(){
    return {
      isAuth: false,
      width: document.documentElement.clientWidth,
      type : 'mobile'
    }
  },
  mounted() {
    this.isAuth = localStorage.getItem('token') !== undefined && localStorage.getItem('token') !== null;
    eventBus.$on('login',()=>{
      this.isAuth = localStorage.getItem('token') !== undefined && localStorage.getItem('token') !== null;
    })
    this.getDimensions();
    window.addEventListener('resize', this.getDimensions);
  },
  unmounted() {
    window.removeEventListener('resize', this.getDimensions);
  },
  methods: {
    logout(){
      localStorage.removeItem('token');
      window.location.reload();
    },
    getDimensions() {
      this.width = document.documentElement.clientWidth;
      if (this.width <= 899){
        this.type = 'mobile'
      }else{
        this.type = 'desktop'
      }
    }
  }
}
</script>

<style scoped>
@media screen  and (max-width: 899px) {
  .mobile-only{
    visibility: visible;
  }
  .left-menu{
    padding: 0px 20px;
  }
  #menu__toggle {
    opacity: 0;
  }
  /* стилизуем кнопку */
  .menu__btn {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0px;
    width: 26px;
    height: 26px;
    cursor: pointer;
    z-index: 1;
  }
  /* добавляем "гамбургер" */
  .menu__btn > span,
  .menu__btn > span::before,
  .menu__btn > span::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #616161;
  }
  .menu__btn > span::before {
    content: '';
    top: -8px;
  }
  .menu__btn > span::after {
    content: '';
    top: 8px;
  }
  .menu__box {
    display: block;
    position: fixed;
    visibility: hidden;
    top: 0;
    left: -100%;
    width: 300px;
    height: 100%;
    margin: 0;
    padding: 80px 0;
    list-style: none;
    text-align: center;
    background-color: #ECEFF1;
    box-shadow: 1px 0px 6px rgba(0, 0, 0, .2);
  }
  /* элементы меню */
  .menu__item {
    display: block;
    padding: 12px 24px;
    color: #333;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
  }
  .menu__item:hover {
    background-color: #CFD8DC;
  }
  #menu__toggle:checked ~ .menu__btn > span {
    transform: rotate(45deg);
  }
  #menu__toggle:checked ~ .menu__btn > span::before {
    top: 0;
    transform: rotate(0);
  }
  #menu__toggle:checked ~ .menu__btn > span::after {
    top: 0;
    transform: rotate(90deg);
  }
  #menu__toggle:checked ~ .menu__box {
    visibility: visible;
    left: 0;
    z-index: 99;
  }
  .header__block{
    display: flex;
    flex-wrap:wrap;

  }
  .header__block > div{
    width: 100%;
  }
  .header__block > div:first-child{
    order: 2;
  }
  .header__block > div:last-child{
    display: none;
  }
}

@media screen and (min-width: 900px) {
  .mobile-only{
    visibility: hidden;
  }
  .header__block{
    display: flex;
  }
}
  .header__wrapper{
    width: 100%;
    background: #FFFFFF;
    padding: 5px 0;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 23px;
    color: #515151;
  }
  .header__block{
    max-width: 1320px;
    margin: 0 auto;
    justify-content: space-between;
    padding: 0 10px;
  }
  .left-menu{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  .left-menu > div:not(:last-child){
    margin-right: 15px;
  }
  .right-menu > div:not(:last-child){
    margin-right: 15px;
  }
  .right-menu{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .left-menu a,.right-menu a{
    text-decoration: none;
    color: #515151;
  }
  .left-menu a:hover, .right-menu a:hover{
    color: #000000;
  }
  a.router-link-active{
    color: #000000;
  }
  .logout{
    cursor: pointer;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 23px;
    color: #DB2D35;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
