<template>
  <div class="share-container">
    <div class="calculator__action__block">
      <div class="car__name">
        {{ car.name }}
      </div>
      <div class="vin_code__wrapper" >
        <div class="text_in__action">
          VIN код
        </div>
        <div class="vin__code__wrapper">
          <div class="vin__code__inputs">
            <div class="first-digits" style="width: 165px">
              {{ car.vin_code }}
            </div>
            <div class="last-4-digits">
              <input disabled type="text" placeholder="Введіть останні цифри" v-model="car.lastFourDigits" maxlength="6">
            </div>
          </div>
        </div>
      </div>

      <div class="inputs__wrapper" >
        <div>
          <div class="text_in__action">
            Рік випуску
          </div>
          <div class="d-flex">
            <input disabled type="number" placeholder="Рік випуску" v-model="car.year" class="input-action-left input-action-font">
          </div>
          <div class="notification">
            перевірте рік випуску на фото шильдіка
          </div>
        </div>
        <div>
          <div class="text_in__action">
            Фінальна ставка USD
          </div>
          <div class="d-flex">
            <input disabled type="number" placeholder="Фінальна ставка USD" v-model="car.price" class="input-action-right input-action-font">
          </div>
        </div>
      </div>

      <div class="inputs__wrapper" >
        <div>
          <div class="text_in__action">
            Збір Аукціону
          </div>
          <div class="d-flex">
            <input disabled type="number" v-model="tax.auction_p" placeholder="Збір Аукціону" class="input-action-left input-action-font">
          </div>
          <div class="notification">
            уточнючте інформацію для Канади
          </div>
        </div>
        <div>
          <div class="text_in__action">
            Доставка до порту ({{ shipping.portTitle }})
          </div>
          <div class="d-flex">
            <input disabled type="number" v-model="shipping.price" placeholder="Доставка до порту" class="input-action-right input-action-font">
          </div>
          <div class="notification">
            не входять тимчасові додаткові платежі
          </div>
        </div>
      </div>

      <div class="shipping-size__wrapper">
        <div class="shipping-size__header">
          Додаткова плата для великих авто
        </div>
        <div class="shipping-icons">
          <div class="crossover">
            <div class="select_shipping">
              <div class="container">
                <input disabled id="crossover" :checked="additional.one" v-model="additional.one" type="radio">
                <label for="crossover" class="checkmark"></label>
              </div>
              <div v-html="icons.crossover">

              </div>
            </div>
            <div>
              Кроссовер <b>+50$</b>
            </div>
          </div>
          <div class="bigSize">
            <div class="select_shipping">
              <div class="container">
                <input disabled id="bigSize" :checked="additional.two" v-model="additional.two" type="radio">
                <label for="bigSize" class="checkmark"></label>
              </div>
              <div v-html="icons.bigSize">

              </div>
            </div>
            <div>
              Великогабаритний <b>+200$</b>
            </div>
          </div>
          <div class="notStandard">
            <div class="select_shipping">
              <div class="container">
                <input disabled id="notStandard" :checked="additional.three" v-model="additional.three" type="radio">
                <label for="notStandard" class="checkmark"></label>
              </div>
              <div v-html="icons.notStandard">

              </div>
            </div>
            <div>
              Нестандартный <b>Х2</b>
            </div>
          </div>
        </div>
      </div>
      <div class="insurance__wrapper">
        <div>
          <div>
            <label class="checkbox style-a">
              <input disabled type="checkbox" v-model="additional.insurence"/>
              <div class="checkbox__checkmark"></div>
              <div class="checkbox__body">Страхування по фото з аукціону</div>
            </label>
          </div>
        </div>
      </div>

      <div class="inputs__wrapper" >
        <div>
          <div class="text_in__action">
            Орієнтовна стоянка в Клайпеді
          </div>
          <div class="d-flex">
            <input type="number" disabled v-model="car.parkingKl" placeholder="Паркінг в Клайпеді на 5 днів" class="input-action-left input-action-font">
          </div>
        </div>
        <div>
          <div class="text_in__action">
            Експедиція, Т-1, погрузка
          </div>
          <div class="d-flex">
            <input type="number" disabled v-model="car.expedition" placeholder="Експедиція, Т-1, погрузка" class="input-action-right input-action-font">
          </div>
        </div>
      </div>

      <div class="inputs__wrapper" >
        <div >
          <div class="text_in__action">
            Евакуатор Клайпеда - Львів, брокер
          </div>
          <div class="d-flex">
            <input type="number" disabled v-model="car.shippingKL" placeholder="Евакуатор Клайпеда - Львів, брокер" class="input-action-left input-action-font">
          </div>
        </div>
        <div>
          <div class="text_in__action">
            Вартість розмитнення у {{ moment().format("YYYY") }} році
          </div>
          <div class="d-flex">
            <input type="number" disabled v-model="tax.customs_clearance" placeholder="Вартість розмитнення у 2024 році" class="input-action-right input-action-font">
          </div>
        </div>
      </div>

      <div class="inputs__wrapper" >
        <div>
          <div class="text_in__action">
            Сертифікація (середня вартість)
          </div>
          <div class="d-flex">
            <input type="number" disabled v-model="car.certP" placeholder="Сертифікація (середня вартість)" class="input-action-left input-action-font">
          </div>
        </div>
        <div>
          <div class="text_in__action">
            Плата до Пенсійного фонду
          </div>
          <div class="d-flex">
            <input type="number" disabled v-model="car.grandPrice" placeholder="Плата до Пенсійного фонду" class="input-action-right input-action-font">
          </div>
        </div>
      </div>

      <div class="inputs__wrapper" >
        <div>
          <div class="text_in__action">
            Послуги компанії
          </div>
          <div class="d-flex">
            <input type="number" disabled v-model="car.planCompany" placeholder="Послуги компанії" class="input-action-left input-action-font border-all">
          </div>
        </div>
        <div class="total__price__wrapper">
          <div class="text_in__action right">
            Загальна вартість
          </div>
          <div class="total__price">
                <span>
                  {{ totalPrice }}$
                </span>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { logo,crossover, bigSize, notStandard } from "@/helpers/helper-svg";
import moment from "moment";

export default {
  name: "shareResult-component",
  data(){
    return {
      moment : moment,
      svgLogo: logo,
      icons:{
        crossover: crossover,
        bigSize: bigSize,
        notStandard: notStandard,
      },
      car:{
        vin_code: "",
        lastFourDigits: "",
        year : 0,
        parking: 0,
        expedition: 0,
        engType: "",
        name : "",
        capacity : "",
        cert : "",
        lic :false,
        renta :false,
        priceWithInsurense: 0,
        sellerType : "",
        evacuation: 0,
        certP: 0,
        eng : 0,
        planCompany: 0,
        shippingKL : 0,
        saleStatus: "",
        parkingKl: 0,
        grandPrice: 0,
        price : 0,
      },
      shipping: {
        price : 0
      },
      totalPrice: 0,
      tax: {
        auction_p : 0,
        customs_clearance : 0,
      },
      additional: {
        insurence : 0,
        one : 0,
        two : 0,
        three : 0,
      },
    }
  },
  mounted() {
    const data = this.$router.currentRoute.value.query;
    this.car.vin_code = data.vin
    this.car.year = data.year
    this.car.name = data.name
    this.car.lastFourDigits = data.last_4_digits
    this.car.price = data.price
    this.tax.auction_p = data.auction_p
    this.shipping.price = data.shipping
    this.car.parkingKl = data.parkingKl
    this.car.expedition = data.expedition
    this.car.evacuation = data.evacuation
    this.car.grandPrice = data.grandPrice
    this.car.shippingKL = data.shippingKL
    this.tax.customs_clearance = data.custom
    this.car.certP = data.certP
    this.totalPrice = data.totalPrice
    this.car.planCompany = data.planCompany
    this.additional[data.shippingType] = true
    this.additional.insurence = data.insurence === "true"
    console.log(data);
  }
}
</script>

<style scoped>
.share-container{
  max-width: 600px;
  margin: 0 auto;
  padding: 10px;
  background: white;
  border-radius: 10px;
}
.inputs__wrapper{
  display: flex;
  margin: 10px 0;
}
.inputs__wrapper > div{
  width: 50%;
}
.vin__code__wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.last-4-digits input{
  background: #FFFFFF;
  outline: 1px solid #D9D9D9;
  border-radius: 10px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  border: none;
  line-height: 23px;
  color: #949494;
  padding: 0 10px;
  min-height: 41px;
}
.first-digits{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  padding: 0 20px;
  min-height: 41px;
  display: flex;
  align-items: center;
}
.vin__code__inputs{
  display: flex;
  background: #EBEBEB;
  border-radius: 10px;
  position: relative;
}
.copy__btn:hover{
  background: #292929;
  color: #ffffff;
}
.copy__btn:hover svg path{
  fill: #ffffff;
}
.copy__btn{
  cursor: pointer;
  background: #FFFFFF;
  outline: 1px solid #000000;
  border-radius: 10px;
  display: flex;
  align-items: center;
  max-width: 228px;
  justify-content: center;
  padding: 0 10px;
  min-height: 41px;
}
.text_in__action{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #525C6A;
  text-align: left;
}

.d-flex{
  display: flex;
}

.calculator__main__block > div:first-child{
  max-width: 615px;
  width: 100%;
}
.calculator__main__block > div:last-child{
  max-width: 615px;
  width: 100%;
}

.search__car__wrapper > div:first-child{
  width: 78%;
}
.search__car__wrapper > div:last-child{
  width: 20%;
}
.search__car__block .cross{
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.input-action-left{
  background: #FFFFFF;
  outline: 1px solid #D0D3D7;
  border-radius: 10px 0px 0px 10px;
  width: 100%;
  padding: 0 20px;
  min-height: 42px;
  max-height: 42px;
  border: none;
}
.input-action-right{
  background: #FFFFFF;
  border: none;
  outline: 1px solid #D0D3D7;
  border-radius: 0px 10px 10px 0px;
  width: 100%;
  min-height: 42px;
  max-height: 42px;
  padding: 0 20px;
}
.input-action-font{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #1E1E1E;
}
.notification{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: #000000;
}
.shipping-size__wrapper{
  background: #f1f1f1;
  padding: 10px;
}

.select_shipping{
  display: flex;
  align-items: center;
}
.shipping-size__header{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  margin: 10px 0;
}
.insurance__wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0;

}
.knows-about-in{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #DB2D35;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.crossover,.bigSize,.notStandard{
  cursor: pointer;
}

.total__price{
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  color: #000000;
  text-align: right;
  position: absolute;
  top: 40px;
  right: 0;
}
.right{
  text-align: right;
}
.total__price__wrapper{
  position: relative;
}

.search__car__block .input{
  padding: 20px 50px 20px 20px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input:focus {
  outline: 1px solid #88ABFF;
}
.border-all{
  border-radius: 10px;
}
.share__car_header{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  margin-right: 10px;
}
.calculator__share__car{
  display: flex;
  justify-content: center;
  align-items: center;
}
.share-icons-list{
  display: flex;
}

.share-icons-list > div{
  cursor: pointer;
  margin-right: 10px;
}

.car__name{
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 36px;
  text-align: center;
  color: #000000;
}

@media (min-width: 320px) and (max-width: 1279px)  {
  .total__price{
    top: 60px;
    right: 20px;
  }

  .text_in__action{
    height: 50px;
    display: flex;
    align-items: end;
    justify-content: right;
  }
  .select_shipping{
    justify-content: center;
    margin-bottom: 15px;
  }

  .search__car__wrapper > div:first-child{
    width: 100%;
    margin: 10px auto;
  }
  .search__car__wrapper > div:last-child{
    width: 100%;
  }

  .calculator__main__block > div:first-child{
    margin: 0 auto;
  }
  .calculator__main__block > div:last-child{
    margin: 0 auto;
  }

  .vin__code__inputs{
    justify-content: space-between;
  }
  .inputs__wrapper{
    display: flex;
    margin: 5px 0;
  }
}
@media (min-width: 1240px)  {
  .vin__code__wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .search__car__wrapper > div:first-child{
    width: 78%;
  }
  .search__car__wrapper > div:last-child{
    width: 20%;
  }

  .shipping-icons{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .first-digits{
    width: 165px
  }
  .inputs__wrapper{
    display: flex;
    margin: 10px 0;
  }
}
</style>
