<template>
  <div class="login__wrapper">
    <div class="login__block">
      <div class="login__header">
        Logowanie
      </div>
      <div class="login__form">
          <custom-input
              v-model="form.username"
              :type="'text'"
              :name="'e-mail'"
              :placeholder="'Imię lub e-mail'"
              :error="error.username"
          />
          <custom-input
              v-model="form.password"
              :name="'hasło'"
              :type="'password'"
              :placeholder="'Proszę wprowadzić hasło'"
              :error="error.password"
          />
          <custom-btn @click="submit" :large="true" :title="'Zaloguj się'" :error="btn.error"/>
      </div>
<!--      <div class="helper__wrapper">-->
<!--        Jeśli masz problemy z logowaniem, skontaktuj się ze swoim menedżerem lub pod numerem telefonu <br> <span class="red">+380 97 514 27 27</span>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import CustomInput from "@/components/custom-input";
import CustomBtn from "@/components/custom-btn";
import axiosClient from "@/axios/axios";
import {localstorageSet} from "@/helpers/helper";
import eventBus from "@/eventBus";
import helperAuction from "@/helpers/helper-auction";

export default {
  name: "login-component",
  components: {CustomInput,CustomBtn},
  data(){
    return {
      error: {
        username : "",
        password : "",
      },
      btn: {
        error: "",
      },
      form: {
        username : "",
        password : "",
      }

    }
  },
  methods:{
    validate(){
      this.error.username = this.form.username === '' ? "Поле 'Ім'я або email' не може бути порожнім!" : ""
      this.error.password = this.form.password === '' ? "Поле 'Пароль' не може бути порожнім!" : ""

      if(this.error.password.length > 0 || this.error.username.length > 0){
        return false
      }

      return true;
    },
    async submit(){
      if(!this.validate()){
        return "";
      }
      axiosClient.post('/auth/login', {
        email : this.form.username,
        password : this.form.password,
      }).then(({data})=>{
        localstorageSet('token',data.token);
        helperAuction.token();
        eventBus.$emit('login');
        this.$router.push({
          name : 'calculator'
        })
      }).catch(({response})=>{
        if(response.status === 422){
          if(response.data.errors.password){
            this.btn.error = 'Пароль має бути з 6 та більше символів!';
          }
        }
        if(response.status === 401){
          this.btn.error = 'Аккаунт з такими доступами не знайден!';
        }
      });

    }
  }
}
</script>

<style scoped>
.login__form{
  margin-bottom: 25px;
}
.login__wrapper{
  max-width: 421px;
  margin: 0 auto;
  position: relative;
}
.login__header{
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 46px;
  color: #000000;
  margin-bottom: 25px;
}

.helper__wrapper{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;
  text-align: center;
  color: #2B2B2B;
}

@media (min-width: 320px) and (max-width: 1279px)  {
  .login__block {
    padding: 10px 30px;
    background: rgba(255,255,255,0.8);
    border-radius: 24px;
    box-shadow: 0px 2px 30px rgba(18, 31, 39, 0.15);
    position: absolute;
    top: 50px;
    margin: 0 auto;
  }
}
@media (min-width: 1240px)  {
  .login__block{
    transform: translateX(-50px);
    width: 500px;
    padding: 20px 50px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 24px;
    box-shadow: 0px 2px 30px rgba(18, 31, 39, 0.15);
    position: absolute;
    top: 50px;
  }
}
.red{
  color: red;
}
</style>
