<template>
  <div  class="doc">
    <div>{{ doc.id }} || {{ doc.vin }}</div>
    <div v-if="doc.status !== 1">
      <loaderSmall/>
    </div>
    <div v-if="doc.status === 1 && !loader" class="carfax__download" @click="Download(doc.id)">
      <div>
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_242_2287)">
            <path d="M13.5 10.5H18.5L12.5 16.5L6.5 10.5H11.5V3.5H13.5V10.5ZM4.5 19.5H20.5V12.5H22.5V20.5C22.5 20.7652 22.3946 21.0196 22.2071 21.2071C22.0196 21.3946 21.7652 21.5 21.5 21.5H3.5C3.23478 21.5 2.98043 21.3946 2.79289 21.2071C2.60536 21.0196 2.5 20.7652 2.5 20.5V12.5H4.5V19.5Z" fill="black"/>
          </g>
          <defs>
            <clipPath id="clip0_242_2287">
              <rect width="24" height="24" fill="white" transform="translate(0.5 0.5)"/>
            </clipPath>
          </defs>
        </svg>
      </div>
      <div>
        Завантажити Car Fax
      </div>
    </div>
    <div v-if="loader">
      <loaderSmall/>
    </div>
  </div>
</template>

<script>
import loaderSmall from "@/components/loader-small";
import axiosClient from "@/axios/axios";
export default {
  name: "carfax-doc",
  components: {loaderSmall},
  props:['doc'],
  data(){
    return {
      loader : false
    }
  },
  methods:{
    Download(id)
    {
      this.loader = true;
      axiosClient.get('/downloadPdfById/' + id).then(({data})=>{
        let fileLink = document.createElement('a');
        fileLink.href = "data:application/pdf;base64,"+data.data;
        fileLink.setAttribute('download', data.name);
        document.body.appendChild(fileLink);
        fileLink.click();
        this.loader = false;
      })

    },
  }
}
</script>

<style scoped>
.carfax__download{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.carfax__download > div:first-child{
  margin-right: 15px;
}

@media (min-width: 320px) and (max-width: 1279px)  {
  .carfax__download{
    margin-top: 20px;
  }
}
@media (min-width: 1240px)  {
}
</style>
